import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ProjetSection: React.FC = () => {
  const listProjet = [
    {
      id: 1,
      title: 'Sénégal',
      description:
        'Mise en place du dispositif de suivi de la transhumance au Sénégal grâce à la géolocalisation pour le compte du PRAPS2 Sénégal.',
      iconName: 'fa-cow',
    },
    {
      id: 2,
      title: 'Mauritanie',
      description:
        'Mise en place du dispositif d’identification électronique et de suivi du bétail bovin dans le cadre du programme national d’insémination des races avec le Ministère de l’élevage et l’office National de Recherche et Développement du Pastoralisme (ONARDEP) ',
      iconName: 'location-dot',
    },
    {
      id: 3,
      title: `Côte d'Ivoire`,
      description:
        'Mise en place du système national d’identification électronique du bétail en partenariat avec le Ministère des Ressources Animales et Halieutiques.',
      iconName: 'fa-file',
    },
  ];
  return (
    <div className="pt-[4rem] md:pt-[8rem] pb-[5rem]">
      <p className="heading">Projets en cours</p>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-[80%] mx-auto items-center gap-[3rem] mt-[4rem] text-white"> */}
      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3  w-[80%] mx-auto justify-items-center items-center gap-[3rem] mt-[4rem] text-white">
        {listProjet.map((i) => (
          <div
            key={i.id}
            data-aos="zoom-in"
            data-aos-delay="300"
            className="flex justify-center text-center"
          >
            <div className="bg-[#108236] hover:scale-110 transform transition-all duration-300    text-center p-[2rem] w-full h-[400px] md:h-[400px]">
              {/* <i className={`fab ${i.iconName} fa-lg`}></i> */}
              <h2 className="text-lg sm:text-xl mt-[1.5rem] uppercase  font-semibold">
                {i.title}
              </h2>
              <p className="text-lg sm:text-xl  text-[#d3d2d2] font-normal">
                {i.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ProjetSection;
