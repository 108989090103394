import React from 'react';
import aboutJpg from 'asset/about.jpg';

const AboutSection: React.FC = () => {
  return (
    <div className=" w-full pb-[3rem] pt-[4rem] md:pt-[8rem]">
      <h2 className="heading ">À propos de BetaClic</h2>
      <div className="grid grid-cols-1 md:grid-cols-2 w-[80%] mx-auto gap-[3rem] items-center">
        <div>
          <div className="mb-[3rem] flex flex-col justify-center  items-center">
            <span className="w-[100px] hidden md:block h-[5px] bg-black rounded-sm mb-2"></span>
            <p className="text-[19px] text-black w-[80%]">
              BetaClic est une société spécialisée dans le suivi et la
              traçabilité du bétail, utilisant des technologies de pointe pour
              soutenir le développement des chaînes de valeur agricoles.
            </p>
          </div>
        </div>

        <div
          data-aos="fade-left"
          data-aos-delay="300"
          className="lg:w-[500px] mx-auto md:mx-0 mt-[0.25rem]  md-mt-[2rem] lg:mt-0 lg:h-[500px] w-[300px] h-[300px] relative right-[2rem] "
        >
          <img
            src={`${aboutJpg}`} // Remplacez par l'URL de votre image
            //alt="a propos"
            // className="w-full h-auto object-cover"
            className="relative z-[11] w-[100%] h-[70%] object-cover rounded-md" // Ajustez la hauteur pour les écrans larges
            alt="Betaclic"
            width="80"
            height="40"
            title="Betaclic"
            loading="lazy"
          />

          <div className="absolute w-[100%] h-[60%] z-[10] bg-[#108236] top-[2rem] right-[-2rem]"></div>
        </div>
      </div>
      <div className="flex flex-col justify-center  items-center">
        <h2 className="text-lg sm:text-xl md:leading-[2rem] leading-[1rem] capitalize mb-[1rem] font-bold text-black">
          <span className="text-[#108236]">Mission & Vision </span>
          <span className="text-black"></span>
        </h2>
        <p className="text-lg sm:text-xl text-black w-[80%] relative italic before:content-['“'] after:content-['”'] before:text-3xl after:text-3xl before:relative before:-left-1 after:relative after:-right-1">
          Nous croyons en l&apos;innovation, aux partenariats stratégiques et en
          l&apos;intelligence collective pour améliorer durablement
          l&apos;élevage en Afrique.
        </p>
      </div>
    </div>
  );
};

export default AboutSection;
