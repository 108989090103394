import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';

const ServiceSection: React.FC = () => {
  const listServices = [
    {
      id: 1,
      title: 'Identification électronique du bétail ',
      description:
        'Garantie la traçabilité des produits et des informations (effectifs du cheptel, couverture vaccinale ...) sur le bétail et lutter contre le vol.',
      iconName: 'fa-cow',
    },
    {
      id: 2,
      title: 'Géolocalisation et télédétection ',
      description:
        'Permet un accès en temps réel sur la mobilité du bétail et l’utilisation des ressources pastorales pour une prise de décision informée dans la gestion durable des ressources, la sécurisation du bétail et la prévention des conflits entre agriculteurs et éleveurs.',
      iconName: 'fa-map-marker-alt',
    },
    {
      id: 3,
      title: `Suivi vétérinaire et zootechnique `,
      description:
        'Accompagnement des producteurs dans la conduite de leur élevage pour améliorer le bien être animal et la productivité du bétail. ',
      iconName: 'fa-stethoscope',
    },
    {
      id: 4,
      title: 'Conseil pastoral & formation ',
      description:
        'Accompagnement des éleveurs dans la gestion de leur bétail et le développement de capacité autour des nouvelles méthodes et techniques de gestion du bétail. ',
      iconName: 'fa-chalkboard-teacher',
    },
    {
      id: 5,
      title: `Accompagnement des éleveurs dans la gestion de leur bétail et le développement de capacité autour des nouvelles méthodes et techniques de gestion du bétail.`,
      description:
        'Mise à disposition d’outils et d’informations fiables pour une prise de décision informée dans la conduite de politique d’élevage.  ',
      iconName: 'fa-road',
    },
    {
      id: 6,
      title: `Une plateforme innovante intégrée pour une vision à 360°C sur le bétail autour de 4 piliers clés`,
      description:
        'La collecte et la gestion des données, la digitalisation des processus, l’aide à la prise de décision et les services à forte valeur ajoutée. ',
      iconName: 'fa-lightbulb',
    },
  ];
  return (
    <div className="pt-[4rem] md:pt-[8rem] pb-[5rem]">
      <h2 className="heading">Nos solutions et services</h2>
      {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 w-[80%] mx-auto items-center gap-[3rem] mt-[4rem] text-white"> */}
      <div className="grid grid-cols-1 md:grid-cols-2  w-[80%] mx-auto items-center gap-[3rem] mt-[4rem] text-white">
        {listServices.map((i) => (
          <div
            key={i.id}
            data-aos="zoom-in"
            data-aos-delay="300"
            className="flex justify-center text-center"
          >
            <div className="bg-[#108236] hover:scale-110 transform transition-all duration-300    text-center p-[2rem] w-full h-[500px] md:h-[400px]">
              {/* <i className={`fab ${i.iconName} fa-lg`}></i> */}
              <div className="absolutes top-3 right-1 md:top-4 md:right-2 w-[3rem] h-[3rem] md:w-[4rem] md:h-[4rem] flex items-center justify-center rounded-full bg-black">
                <i className={`fas ${i.iconName} fa-lg`}></i>
              </div>
              <h2 className="text-lg sm:text-xl mt-[1.5rem] uppercase  font-semibold">
                {i.title}
              </h2>
              <p className="text-lg sm:text-xl  text-[#d3d2d2] font-normal">
                {i.description}
              </p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ServiceSection;
