import React, { ReactNode } from 'react';

interface SectionProps {
  id: string;
  //title: string;
  //content: string;
  children: ReactNode;
}

const Section: React.FC<SectionProps> = ({ id, children }) => {
  return (
    <section id={id} className="py-2 px-2 text-center">
      {/* <h3 className="text-2xl font-semibold mb-4">{title}</h3>
      <p>{content}</p> */}
      {children}
    </section>
  );
};

export default Section;
