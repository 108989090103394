import React from 'react';

const ContextSection: React.FC = () => {
  const listDifficulties = [
    { id: 1, text: 'Identifier et recenser le bétail national.' },
    {
      id: 2,
      text: 'Contrôler et surveiller les maladies animales et les intrants (médicaments, vaccins, etc …).',
    },
    {
      id: 3,
      text: `Suivre les parcours du bétail, y compris dans leurs dimensions transfrontalières et l'utilisation des ressources pastorales.`,
    },
    {
      id: 4,
      text: 'Avoir des données fiables et précises pour une prise de décision informée.',
    },
    {
      id: 5,
      text: `Faciliter l'accès des produits (bétails, lait) aux marchés locaux, sous régionaux et internationaux.`,
    },
  ];

  return (
    <div className="">
      <h2 className="heading ">Contexte</h2>
      <h2 className="text-2xl font-bold mb-4 text-center">
        En Afrique, les acteurs de l&apos;élevage rencontrent des difficultés
        pour :
      </h2>
      <div className="w-[80%] pt-[2rem] mx-auto grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem]">
        {/* <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-[2rem]  pt-[2rem] mx-auto"> */}
        {listDifficulties.map((i) => (
          <div
            key={i.id}
            data-aos="fade-up"
            data-aos-delay="300"
            className="flex justify-center items-center"
          >
            <div className="transform cursor-pointer hover:-translate-y-6 transition-all duration-200 relative p-2 w-full h-[200px] md:h-[250px] bg-[#108236] rounded-2xl flex justify-center items-center">
              <p className="text-white text-center">{i.text}</p>
            </div>
          </div>
        ))}
      </div>
    </div>
  );
};

export default ContextSection;
