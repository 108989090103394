import React from 'react';
import '@fortawesome/fontawesome-free/css/all.min.css';
import APPCOLORS from 'constant/color';

interface CustomCSSProperties extends React.CSSProperties {
  '--hover-color'?: string; // Add custom CSS variable here
}
const Footer: React.FC = () => {
  const socialMediaItems = [
    {
      id: 1,
      iconName: 'fa-instagram',
      href: 'https://www.instagram.com/betaclic.mr',
      altText: 'instagram',
    },
    {
      id: 2,
      iconName: 'fa-facebook',
      href: 'https://www.facebook.com/BetaClic/',
      altText: 'facebook',
    },
    {
      id: 3,
      iconName: 'fa-linkedin',
      href: 'https://www.linkedin.com/company/betaclic/',
      altText: 'linkedin',
    },
  ];
  return (
    <footer className="fixed   bottom-0 mt-5 w-full bg-gray-500 text-white py-1 text-center z-50">
      {/* <footer className="fixed   bottom-0 mt-5 w-full  bg-[#108236] text-white py-1 text-center z-50"> */}
      <div className="mt-1">
        {socialMediaItems.map((i) => (
          <a
            key={i.id}
            href={i.href}
            target="_blank"
            // rel="noopener noreferrer"
            className="mx-2 text-white hover:bg-[var(--hover-color)]"
            style={
              { '--hover-color': APPCOLORS.primary } as CustomCSSProperties
            }
            aria-label="réseaux sociaux"
            rel="noreferrer"
            // rel="noreferrer"
          >
            <i className={`fab ${i.iconName} fa-lg`}></i>
            <span className="sr-only">{i.altText || 'réseaux sociaux'}</span>
          </a>
        ))}
      </div>
      <p>© 2024 Betaclic - Tous droits réservés</p>
    </footer>
  );
};

export default Footer;
